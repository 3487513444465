import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import "../styles.css";

const Navbar = () => {
  const { user } = useAuth();

  const handleLogout = async () => {
    await signOut(auth);
    window.location.reload(); // Force reload to clear state
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          Baby Names Admin
        </Link>
        <div className="navbar-links">
        <Link to="/dashboard" className="navbar-link">
            Dashboard
          </Link>
          <Link to="/manage-names" className="navbar-link">
            Manage Names
          </Link>
          <Link to="/bulk-upload" className="navbar-link">
            Bulk Upload
          </Link>
          <Link to="/analytics" className="navbar-link">
            Analytics
          </Link>
          <Link to="/user-analytics" className="navbar-link">
            User Analytics
          </Link>
          {user && (
            <button className="logout-button" onClick={handleLogout}>
              Logout
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;