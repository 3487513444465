import React, { useEffect, useState } from "react";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import Navbar from "../components/Navbar"; 

const UserAnalyticsPage = () => {
  const [analytics, setAnalytics] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAnalytics = async () => {
      const db = getFirestore();

      try {
        // Fetch total users
        const usersSnapshot = await getDocs(collection(db, "users"));
        const totalUsers = usersSnapshot.size;

        // Fetch total likes
        const likesSnapshot = await getDocs(collection(db, "nameLikes"));
        const totalLikes = likesSnapshot.size;

        // Fetch total dislikes
        const dislikesSnapshot = await getDocs(collection(db, "nameDislikes"));
        const totalDislikes = dislikesSnapshot.size;

        // Calculate total swipes (likes + dislikes)
        const totalSwipes = totalLikes + totalDislikes;

        // Fetch total connections
        const connectionsSnapshot = await getDocs(collection(db, "connections"));
        const totalConnections = connectionsSnapshot.docs.filter((doc) => {
            const { status } = doc.data();
            return status === "confirmed";
          }).length;

        // Calculate averages
        const averageSwipes = totalUsers > 0 ? totalSwipes / totalUsers : 0;
        const averageLikes = totalUsers > 0 ? totalLikes / totalUsers : 0;
        const averageDislikes = totalUsers > 0 ? totalDislikes / totalUsers : 0;

        // Fetch names collection for top names by gender and nationality
        const namesSnapshot = await getDocs(collection(db, "names"));
        const nameData = namesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Aggregate likes/dislikes for each name
        const nameLikes = {};
        const nameDislikes = {};

        likesSnapshot.forEach((doc) => {
          const { nameId } = doc.data();
          nameLikes[nameId] = (nameLikes[nameId] || 0) + 1;
        });

        dislikesSnapshot.forEach((doc) => {
          const { nameId } = doc.data();
          nameDislikes[nameId] = (nameDislikes[nameId] || 0) + 1;
        });

        // Combine name metadata with aggregated likes/dislikes
        const enrichedNames = nameData.map((name) => ({
          ...name,
          likes: nameLikes[name.id] || 0,
          dislikes: nameDislikes[name.id] || 0,
        }));

        // Get top 10 names by gender
        const topNamesByGender = {
          male: enrichedNames
            .filter((name) => name.gender === "male")
            .sort((a, b) => b.likes - a.likes)
            .slice(0, 10),
          female: enrichedNames
            .filter((name) => name.gender === "female")
            .sort((a, b) => b.likes - a.likes)
            .slice(0, 10),
          unisex: enrichedNames
            .filter((name) => name.gender === "unisex")
            .sort((a, b) => b.likes - a.likes)
            .slice(0, 10),
        };

        // Get top 10 names by nationality
        const nationalityCounts = {};
        enrichedNames.forEach((name) => {
          if (!nationalityCounts[name.nationality]) {
            nationalityCounts[name.nationality] = [];
          }
          nationalityCounts[name.nationality].push(name);
        });

        const topNamesByNationality = {};
        for (const nationality in nationalityCounts) {
          topNamesByNationality[nationality] = nationalityCounts[nationality]
            .sort((a, b) => b.likes - a.likes)
            .slice(0, 10);
        }

        // Update state with analytics
        setAnalytics({
          totalUsers,
          totalSwipes,
          averageSwipes,
          totalLikes,
          averageLikes,
          totalDislikes,
          averageDislikes,
          totalConnections,
          topNamesByGender,
          topNamesByNationality,
        });
      } catch (error) {
        console.error("Error fetching analytics:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAnalytics();
  }, []);

  if (loading) return <p>Loading analytics...</p>;
  if (!analytics) return <p>Failed to load analytics.</p>;

  return (
    <>
      <Navbar />
      <div className="analytics-container">
        <h1>User Analytics</h1>
        <div className="analytics-metrics">
          <ul>
            <li><strong>Total Users:</strong> {analytics.totalUsers}</li>
            <li><strong>Total Swipes:</strong> {analytics.totalSwipes}</li>
            <li><strong>Average Swipes per User:</strong> {analytics.averageSwipes.toFixed(2)}</li>
            <li><strong>Total Likes:</strong> {analytics.totalLikes}</li>
            <li><strong>Average Likes per User:</strong> {analytics.averageLikes.toFixed(2)}</li>
            <li><strong>Total Dislikes:</strong> {analytics.totalDislikes}</li>
            <li><strong>Average Dislikes per User:</strong> {analytics.averageDislikes.toFixed(2)}</li>
            <li><strong>Total Connected Pairs:</strong> {analytics.totalConnections}</li>
          </ul>
          <h2>Top 10 Names by Gender</h2>
          <div>
            <h3>Male</h3>
            <ul>
              {analytics.topNamesByGender.male.map((name) => (
                <li key={name.id}>
                  {name.name} - {name.likes} likes
                </li>
              ))}
            </ul>
            <h3>Female</h3>
            <ul>
              {analytics.topNamesByGender.female.map((name) => (
                <li key={name.id}>
                  {name.name} - {name.likes} likes
                </li>
              ))}
            </ul>
            <h3>Unisex</h3>
            <ul>
              {analytics.topNamesByGender.unisex.map((name) => (
                <li key={name.id}>
                  {name.name} - {name.likes} likes
                </li>
              ))}
            </ul>
          </div>
          <h2>Top 10 Names by Nationality</h2>
          {Object.entries(analytics.topNamesByNationality).map(
            ([nationality, names]) => (
              <div key={nationality}>
                <h3>{nationality}</h3>
                <ul>
                  {names.map((name) => (
                    <li key={name.id}>
                      {name.name} - {name.likes} likes
                    </li>
                  ))}
                </ul>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default UserAnalyticsPage;
