import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import Navbar from "../components/Navbar";
import "../styles.css";

const AnalyticsPage = () => {
  const [topNamesByGender, setTopNamesByGender] = useState({});
  const [topNamesByNationality, setTopNamesByNationality] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchAnalytics = async () => {
    setLoading(true);
    setError(null);

    try {
      const likesCollection = collection(db, "nameLikes");
      const likesSnapshot = await getDocs(likesCollection);
      const likesData = likesSnapshot.docs.map((doc) => doc.data());

      const genderCounts = { male: {}, female: {}, unisex: {} };
      const nationalityCounts = {};

      for (const like of likesData) {
        const nameDocRef = doc(db, "names", like.nameId);
        const nameDocSnapshot = await getDoc(nameDocRef);

        if (nameDocSnapshot.exists()) {
          const nameData = nameDocSnapshot.data();
          const { gender, nationality, name } = nameData;

          // Count by gender
          if (gender in genderCounts) {
            genderCounts[gender][name] = (genderCounts[gender][name] || 0) + 1;
          }

          // Count by nationality
          if (nationality) {
            nationalityCounts[nationality] = nationalityCounts[nationality] || {};
            nationalityCounts[nationality][name] =
              (nationalityCounts[nationality][name] || 0) + 1;
          }
        }
      }

      // Process top 10 lists by gender
      const topNamesByGender = {};
      for (const gender in genderCounts) {
        topNamesByGender[gender] = Object.entries(genderCounts[gender])
          .sort((a, b) => b[1] - a[1])
          .slice(0, 10);
      }

      // Process top 10 lists by nationality
      const topNamesByNationality = {};
      for (const nationality in nationalityCounts) {
        topNamesByNationality[nationality] = Object.entries(nationalityCounts[nationality])
          .sort((a, b) => b[1] - a[1])
          .slice(0, 10);
      }

      setTopNamesByGender(topNamesByGender);
      setTopNamesByNationality(topNamesByNationality);
      setLoading(false);
    } catch (err) {
      console.error("Failed to fetch analytics data:", err);
      setError("Unable to fetch analytics data.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAnalytics();
  }, []);

  if (loading) return <p>Loading analytics...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <Navbar />
      <div className="analytics-container">
        <h1>Analytics</h1>

        <div className="analytics-section">
          <h2>Top 10 Names by Gender</h2>
          <div className="analytics-grid">
            {Object.keys(topNamesByGender).map((gender) => (
              <div key={gender} className="analytics-card">
                <h3>{gender}</h3>
                <ul>
                  {topNamesByGender[gender].map(([name, count], index) => (
                    <li key={index}>
                      {name} <span className="count">({count} likes)</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div className="analytics-section">
          <h2>Top 10 Names by Nationality</h2>
          <div className="analytics-grid">
            {Object.keys(topNamesByNationality).map((nationality) => (
              <div key={nationality} className="analytics-card">
                <h3>{nationality}</h3>
                <ul>
                  {topNamesByNationality[nationality].map(([name, count], index) => (
                    <li key={index}>
                      {name} <span className="count">({count} likes)</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AnalyticsPage;