import React, { useState, useEffect } from "react";
import { db } from "../firebase";
import Navbar from "../components/Navbar";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import "../styles.css";

const ManageNamesPage = () => {
  const [names, setNames] = useState([]);
  const [filteredNames, setFilteredNames] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [genderFilter, setGenderFilter] = useState("All");
  const [nationalityFilter, setNationalityFilter] = useState("All");
  const [alphabetFilter, setAlphabetFilter] = useState("");
  const [newName, setNewName] = useState({ name: "", gender: "male", nationality: "" });
  const [isEditing, setIsEditing] = useState(null);
  const [editData, setEditData] = useState({});
  const [selectedNames, setSelectedNames] = useState([]); // Track selected names for bulk delete

  // Fetch names and nationalities
  const fetchNames = async () => {
    try {
      const namesCollection = collection(db, "names");
      const namesSnapshot = await getDocs(namesCollection);
      const namesList = namesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setNames(namesList);

      // Extract unique nationalities
      const uniqueNationalities = Array.from(
        new Set(namesList.map((name) => name.nationality))
      );
      setNationalities(uniqueNationalities);
    } catch (error) {
      console.error("Error fetching names:", error);
    }
  };

  // Fetch names on component load
  useEffect(() => {
    fetchNames();
  }, []);

  // Filter names
  useEffect(() => {
    let filtered = [...names];

    if (alphabetFilter) {
      filtered = filtered.filter((name) =>
        name.name.toLowerCase().startsWith(alphabetFilter.toLowerCase())
      );
    }

    if (nationalityFilter !== "All") {
      filtered = filtered.filter((name) => name.nationality === nationalityFilter);
    }

    if (genderFilter !== "All") {
      filtered = filtered.filter((name) => name.gender === genderFilter);
    }

    setFilteredNames(filtered);
  }, [names, alphabetFilter, nationalityFilter, genderFilter]);

  // Add a new name
  const handleAddName = async (e) => {
    e.preventDefault();

    if (!newName.name || !newName.nationality) {
      alert("All fields are required!");
      return;
    }

    try {
      const namesCollection = collection(db, "names");
      await addDoc(namesCollection, newName);
      setNewName({ name: "", gender: "male", nationality: "" });
      await fetchNames();
      alert("Name added successfully!");
    } catch (error) {
      console.error("Error adding name:", error);
      alert("Failed to add name.");
    }
  };

  // Delete a name
  const handleDelete = async (id) => {
    if (!window.confirm("Are you sure you want to delete this name?")) return;

    try {
      await deleteDoc(doc(db, "names", id));
      await fetchNames();
      alert("Name deleted successfully!");
    } catch (error) {
      console.error("Error deleting name:", error);
      alert("Failed to delete name.");
    }
  };

  // Bulk delete names
  const handleBulkDelete = async () => {
    if (!selectedNames.length) {
      alert("Please select at least one name to delete.");
      return;
    }

    if (!window.confirm("Are you sure you want to delete the selected names?")) return;

    try {
      const promises = selectedNames.map((id) => deleteDoc(doc(db, "names", id)));
      await Promise.all(promises);
      await fetchNames();
      setSelectedNames([]); // Clear selection
      alert("Selected names deleted successfully!");
    } catch (error) {
      console.error("Error deleting names:", error);
      alert("Failed to delete selected names.");
    }
  };

  // Handle checkbox selection
  const toggleSelection = (id) => {
    setSelectedNames((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  // Save changes for inline editing
  const handleSaveEdit = async (id) => {
    try {
      if (!editData.name || !editData.gender || !editData.nationality) {
        alert("All fields must be filled out.");
        return;
      }

      await updateDoc(doc(db, "names", id), {
        name: editData.name,
        gender: editData.gender,
        nationality: editData.nationality,
      });

      setNames(
        names.map((name) => (name.id === id ? { ...name, ...editData } : name))
      );

      setIsEditing(null); // Exit editing mode
      alert("Changes saved successfully!");
    } catch (error) {
      console.error("Error saving changes:", error);
      alert(`Failed to save changes: ${error.message}`);
    }
  };

  return (
    <>
      <Navbar />
      <div className="manage-names-container">
        <h1>Manage Names</h1>

        {/* Add Name Form */}
        <div className="add-name-form">
          <h3>Add a New Name</h3>
          <form onSubmit={handleAddName}>
            <input
              type="text"
              placeholder="Name"
              value={newName.name}
              onChange={(e) => setNewName({ ...newName, name: e.target.value })}
              required
            />
            <select
              value={newName.gender}
              onChange={(e) => setNewName({ ...newName, gender: e.target.value })}
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="unisex">Unisex</option>
            </select>
            <input
              type="text"
              placeholder="Nationality"
              value={newName.nationality}
              onChange={(e) => setNewName({ ...newName, nationality: e.target.value })}
              required
            />
            <button type="submit">Add Name</button>
          </form>
        </div>

        {/* Alphabet Filter */}
        <div className="alphabet-filter">
          {"ABCDEFGHIJKLMNOPQRSTUVWXYZÁÉÍÓÚÝÆÖ".split("").map((letter) => (
            <button
              key={letter}
              className={`alphabet-button ${
                alphabetFilter === letter ? "active" : ""
              }`}
              onClick={() => setAlphabetFilter(letter)}
            >
              {letter}
            </button>
          ))}
          <button onClick={() => setAlphabetFilter("")}>All</button>
        </div>

        {/* Filters */}
        <div className="filter-container">
          <label htmlFor="gender">Filter by Gender:</label>
          <select
            id="gender"
            value={genderFilter}
            onChange={(e) => setGenderFilter(e.target.value)}
          >
            <option value="All">All</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="unisex">Unisex</option>
          </select>

          <label htmlFor="nationality">Filter by Nationality:</label>
          <select
            id="nationality"
            value={nationalityFilter}
            onChange={(e) => setNationalityFilter(e.target.value)}
          >
            <option value="All">All</option>
            {nationalities.map((nat) => (
              <option key={nat} value={nat}>
                {nat}
              </option>
            ))}
          </select>
        </div>

        {/* Names Table */}
        <table className="names-table">
          <thead>
            <tr>
              <th>Select</th>
              <th>Name</th>
              <th>Gender</th>
              <th>Nationality</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredNames.map((name) => (
              <tr key={name.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedNames.includes(name.id)}
                    onChange={() => toggleSelection(name.id)}
                  />
                </td>
                <td>
                  {isEditing === name.id ? (
                    <input
                      type="text"
                      value={editData.name || name.name}
                      onChange={(e) =>
                        setEditData({ ...editData, name: e.target.value })
                      }
                    />
                  ) : (
                    name.name
                  )}
                </td>
                <td>
                  {isEditing === name.id ? (
                    <select
                      value={editData.gender || name.gender}
                      onChange={(e) =>
                        setEditData({ ...editData, gender: e.target.value })
                      }
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="unisex">Unisex</option>
                    </select>
                  ) : (
                    name.gender
                  )}
                </td>
                <td>
                  {isEditing === name.id ? (
                    <input
                      type="text"
                      value={editData.nationality || name.nationality}
                      onChange={(e) =>
                        setEditData({ ...editData, nationality: e.target.value })
                      }
                    />
                  ) : (
                    name.nationality
                  )}
                </td>
                <td>
                  {isEditing === name.id ? (
                    <>
                      <button
                        className="save-button"
                        onClick={() => handleSaveEdit(name.id)}
                      >
                        Save
                      </button>
                      <button
                        className="cancel-button"
                        onClick={() => setIsEditing(null)}
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        className="edit-button"
                        onClick={() => {
                          setIsEditing(name.id);
                          setEditData(name); // Set the current row data for editing
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="delete-button"
                        onClick={() => handleDelete(name.id)}
                      >
                        Delete
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Bulk Delete Button */}
        <button
          onClick={handleBulkDelete}
          disabled={!selectedNames.length}
          className="bulk-delete-button"
        >
          Delete Selected
        </button>
      </div>
    </>
  );
};

export default ManageNamesPage;