import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import "../styles.css";

const DashboardPage = () => {
  return (
    <>
      <Navbar />
      <div className="dashboard-container">
        <h1 className="page-title">Admin Dashboard</h1>
        <div className="dashboard-links">
          <Link to="/manage-names" className="dashboard-card">
            <h2>Manage Names</h2>
            <p>Organize and edit your name database efficiently.</p>
          </Link>
          <Link to="/analytics" className="dashboard-card">
            <h2>Analytics</h2>
            <p>Review detailed usage data and insights.</p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default DashboardPage;