import React, { useState, useEffect } from "react";
import { getFirestore, collection, addDoc, query, where, getDocs, deleteDoc, doc } from "firebase/firestore";
import Papa from "papaparse";
import Navbar from "../components/Navbar";

const BulkUploadPage = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [nationalities, setNationalities] = useState([]);
  const [selectedNationality, setSelectedNationality] = useState("");
  const [deleteStatus, setDeleteStatus] = useState("");

  const db = getFirestore();

  // Fetch unique nationalities for the dropdown
  useEffect(() => {
    const fetchNationalities = async () => {
      try {
        const namesSnapshot = await getDocs(collection(db, "names"));
        const uniqueNationalities = new Set();

        namesSnapshot.forEach((doc) => {
          const { nationality } = doc.data();
          if (nationality) {
            uniqueNationalities.add(nationality.toLowerCase());
          }
        });

        setNationalities(Array.from(uniqueNationalities));
      } catch (error) {
        console.error("Error fetching nationalities:", error);
      }
    };

    fetchNationalities();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      setUploadStatus("Please select a CSV file to upload.");
      return;
    }

    setUploadStatus("Uploading...");

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async function (results) {
        const namesCollection = collection(db, "names");

        try {
          const promises = results.data.map(async (row) => {
            if (row.name && row.gender && row.nationality) {
              await addDoc(namesCollection, {
                name: row.name,
                gender: row.gender.toLowerCase(), // Ensure consistent format
                nationality: row.nationality.toLowerCase(),
              });
            }
          });

          await Promise.all(promises);
          setUploadStatus("Bulk upload completed successfully!");
        } catch (error) {
          console.error("Error uploading names:", error);
          setUploadStatus("Error during upload. Please check the file format and try again.");
        }
      },
      error: function (error) {
        console.error("Error parsing CSV file:", error);
        setUploadStatus("Error parsing file. Please check the file and try again.");
      },
    });
  };

  const handleDeleteByNationality = async () => {
    if (!selectedNationality) {
      setDeleteStatus("Please select a nationality to delete.");
      return;
    }

    const userConfirmed = window.confirm(
      `Are you sure you want to delete all names with the nationality "${selectedNationality}"? This action cannot be undone.`
    );

    if (!userConfirmed) {
      setDeleteStatus("Deletion cancelled by user.");
      return;
    }

    setDeleteStatus("Deleting...");

    try {
      const namesCollection = collection(db, "names");
      const q = query(namesCollection, where("nationality", "==", selectedNationality));
      const querySnapshot = await getDocs(q);

      const promises = querySnapshot.docs.map(async (docSnapshot) => {
        await deleteDoc(doc(db, "names", docSnapshot.id));
      });

      await Promise.all(promises);
      setDeleteStatus(`All names with nationality "${selectedNationality}" have been deleted.`);
    } catch (error) {
      console.error("Error deleting names:", error);
      setDeleteStatus("Error during deletion. Please try again.");
    }
  };

  return (
    <>
      <Navbar />
      <div className="upload-container">
        <h1>Bulk Upload Names</h1>
        <p>Upload a CSV file containing the names you want to add to the database.</p>
        <input type="file" accept=".csv" onChange={handleFileChange} />
        <button onClick={handleUpload} className="upload-button">
          Upload
        </button>
        {uploadStatus && <p className="upload-status">{uploadStatus}</p>}

        <h2>Bulk Delete Names by Nationality</h2>
        <p>Select the nationality of the names you want to delete.</p>
        <select
          value={selectedNationality}
          onChange={(e) => setSelectedNationality(e.target.value)}
          className="delete-dropdown"
        >
          <option value="">Select a nationality</option>
          {nationalities.map((nationality) => (
            <option key={nationality} value={nationality}>
              {nationality.charAt(0).toUpperCase() + nationality.slice(1)}
            </option>
          ))}
        </select>
        <button onClick={handleDeleteByNationality} className="delete-button">
          Delete by Nationality
        </button>
        {deleteStatus && <p className="delete-status">{deleteStatus}</p>}
      </div>
    </>
  );
};

export default BulkUploadPage;
